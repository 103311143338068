import React, { useState, createContext } from 'react';
import dynamic from 'next/dynamic';
import cookie from 'cookie-cutter';
import jwt from 'jsonwebtoken';
import dayjs from 'dayjs';
// import * as Sentry from '@sentry/nextjs';

const ContextStore = createContext({});

const parseCookie = () => ((typeof window !== 'undefined' && cookie.get('token')) ? jwt.decode(cookie.get('token')) : {});

function Provider({ children }) {
	const [userState, setUserState] = useState(parseCookie());
	const [rangeState, _setRangeState] = useState({ start: dayjs('2011-01-01').format('YYYY-MM-DD'), end: dayjs().format('YYYY-MM-DD'), unit: 'y'});
	const logout = () => {
		cookie.set('token', '', { path: '/', expires: new Date(0) });
		setUserState({});
	};
	const setRangeState = (data) => {
		_setRangeState({ ...rangeState, ...data });
	};

	/*
	const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
	if (SENTRY_DSN) {
		const {
			_id, user, mail, ...state
		} = userState;
		Sentry.setUser({
			id: _id,
			username: user,
			...state,
		});
	}
    */

	return (
		<ContextStore.Provider
			// eslint-disable-next-line react/jsx-no-constructed-context-values
			value={{
				userState,
				setUserState,
				logout,
				rangeState,
				setRangeState,
			}}
		>
			{ children }
		</ContextStore.Provider>
	);
}

export default dynamic(() => Promise.resolve(Provider), { ssr: false });
export {
	ContextStore,
	parseCookie,
};
