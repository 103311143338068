import React from 'react';
import Script from 'next/script';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { SWRConfig, useSWRConfig } from 'swr';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'react-vis/dist/style.css';
import 'leaflet/dist/leaflet.css';
import 'react-datepicker/dist/react-datepicker.css';
import Provider from '../contexts/authContext';
import myTheme from '../components/theme';
import { fetcher } from '../utils/axios';
import '../public/nprogress.css';

config.autoAddCss = false;
const theme = extendTheme(myTheme);

function MyApp({ Component, pageProps }) {
	const { cache } = useSWRConfig();
	return (
		<Provider>
			<ChakraProvider theme={theme}>
				<SWRConfig
					value={{
						fetcher,
						onError: (e) => {
							switch (e?.response?.status) {
							case 401:
							// case 404:
								cache.clear();
								break;
							default:
							}
						},
					}}
				>
					<Script strategy="afterInteractive" src="https://www.googletagmanager.com/gtag/js?id=G-MFKYR264F3" />
					<Script
						id="google-anaalytics"
						strategy="afterInteractive"
						dangerouslySetInnerHTML={{
							__html: `
								window.dataLayer = window.dataLayer || [];
								function gtag(){dataLayer.push(arguments);}
								gtag('js', new Date());

								gtag('config', 'G-MFKYR264F3');
							`,
						}}
					/>
					<Component {...pageProps} />
				</SWRConfig>
			</ChakraProvider>
		</Provider>
	);
}

export default MyApp;
