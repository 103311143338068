export default {
	colors: {
		pryBlue: '#010048',
		pryPurple: '#5D65AA',
		pryPink: '#E25A92',
		dark: '#152536',
		secDark: '#6C757D',
		error: '#F1283B',
		success: '#00AA30',
		disabled: '#DEE2E6',
		secWarning: '#F8D7DA',
		// white: '',
		// background: '#F2F2F2',
		bgGray: '#E9E9E9',
		// gradient: '',

		background: '#001449',
		primary: '#00F3FF',
		navText: '#FFFFFF',
		text: '#000000',
		title: '#E9E9E9',
		subtitle: '#DDDDDD',
		graph2: '#F4D73F',
		graph3: '#FD4691',
		graph4: '#5E6BDD',
		graph5: '#F0734C',
		graph6: '#F0F26D',
		graph7: '#3CDF42',
		graph8: '#fa8585',
		graph9: '#959595',
		graph10: '#2894FF',
		markerActive: '#FF5151',
		heatmapDark: '#336885',
	},
	fonts: {
		heading: '"Noto Sans TC", sans-serif',
		body: '"Noto Sans TC", sans-serif',
	},
};
